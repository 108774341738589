<template>
  <teleport to="#overlay">
    <div class="overlay w100 h100 abs">
      <div class="overlay-panel rel pb24">
        <form
          class="flex-container flex-col min-height-inherit"
          id="panel"
          @submit.prevent="onSubmit"
        >
          <div class="panel-header p24">
            <div class="mini-logo"></div>
            <h2 class="panel-title nomargin bebas">Crea Nuova Campagna</h2>
          </div>
          <keep-alive>
            <div
              v-if="data.step === 0"
              class="flex-container flex-col scrollable-content pl24 pr24 pb24"
              style="height: 50vh"
            >
              <TextInput
                :label="'nome campagna'"
                :value="data.title || ''"
                :name="'title'"
                ref="title"
                :editing="true"
                :placeholder="'inserisci nome campagna'"
                :className="'flex-container flex-col no-grow pb16'"
                @input="onTitleInput"
              >
              </TextInput>
              <SelectInput
                :label="'Tipologia campagna'"
                :value="data.type || ''"
                :editing="true"
                ref="type"
                :name="'type'"
                :placeholder="'scegli il tipo di campagna'"
                :options="['Facebook', 'Instagram']"
                :className="'flex-container flex-col no-grow pb16'"
                @select-change="onTypeChange"
              ></SelectInput>
              <SelectInput
                :label="'Tipologia di Media'"
                :value="data.mediaType || ''"
                :editing="true"
                :name="'mediaType'"
                ref="mediaType"
                :placeholder="'scegli il tipo di media'"
                :options="['Image', 'Video']"
                :className="'flex-container flex-col no-grow pb16'"
                @select-change="onMediaTypeChange"
              ></SelectInput>
              <PriceInput
                :label="'budget campagna'"
                :value="data.budget || ''"
                :editing="true"
                :placeholder="'inserisci il budget'"
                :className="'flex-container flex-col no-grow pb24'"
                :isRequired="false"
                @input="onBudgetInput"
              >
              </PriceInput>
            </div>
          </keep-alive>

          <keep-alive>
            <div
              v-if="data.step === 1"
              class="flex-container flex-col pl24 pr24 pb24"
            >
              <div class="flex-container flex-col">
                <AjaxSearchSelect
                  :config="{
                    mode: 'tags',
                    trackBy: 'name',
                    label: 'name',
                    valueProp: 'name',
                  }"
                  :feedUrl="ApiEndPoints.TAGS"
                  @select="onTagSelect"
                  @deselect="onTagDeSelect"
                  :selected="data.selectedTags"
                  label="target associati alla pagina "
                  :className="'flex-container flex-col mb8 pl8 no-grow'"
                >
                </AjaxSearchSelect>
                <AjaxSearchSelect
                  v-if="me.roles[0] !== USER_ROLE_ADVERTISER"
                  :config="{
                    mode: 'single',
                    trackBy: 'userName',
                    label: 'userName',
                    valueProp: 'userName',
                  }"
                  label="Advertiser"
                  :feedUrl="ApiEndPoints.USERS"
                  :params="{
                    roles: 'Advertiser',
                  }"
                  @select="onAdvertiserChange"
                  :selected="data?.advertiser || null"
                  :className="'flex-container flex-col mb8 pl8 no-grow'"
                >
                </AjaxSearchSelect>
              </div>
            </div>
          </keep-alive>

          <keep-alive>
            <div
              v-if="data.step === 2"
              class="flex-container flex-col scrollable-content pl24 pr24 pb24"
            >
              <TextAreaInput
                :label="'descrizione'"
                :value="data.description || ''"
                :editing="true"
                :placeholder="'inserisci una breve descrizione'"
                ref="description"
                :className="'flex-container flex-col add-description pb16'"
                @input="descriptionInput"
              ></TextAreaInput>
            </div>
          </keep-alive>
          <keep-alive>
            <div
              v-if="data.step === 3"
              class="flex-container flex-col scrollable-content pl24 pr24 pb24"
            >
              <linked-calendars
                :startDate="initStartDay"
                :endDate="initEndDay"
                :editing="true"
                @data-change="onDatesChanged"
              ></linked-calendars>
            </div>
          </keep-alive>

          <div class="flex-container panel-footer abs w100">
            <div class="flex-container">
              <input
                type="reset"
                :value="data.step === 0 ? 'annulla' : 'indietro'"
                class="w100 noborder nopadding bebas"
                @click.prevent="prev"
              />
              <input
                type="submit"
                :value="nextButtonText"
                class="w100 noborder nopadding bebas"
                :disabled="preventNext"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </teleport>
</template>
<script>
import { computed, reactive, ref, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ApiEndPoints } from "@/api/Endpoints";
import AjaxSearchSelect from "@/components/formelements/AjaxSearchSelect";
import TextInput from "@/components/formelements/TextInput";
import SelectInput from "@/components/formelements/SelectInput";
import PriceInput from "@/components/formelements/PriceInput";
import TextAreaInput from "@/components/formelements/TextAreaInput";
import LinkedCalendars from "@/components/LinkedCalendars";
import { USER_ROLE_ADVERTISER } from "@/utils/RolesAndPolicies";
export default {
  emits: ["close"],
  components: {
    /* MultiTag, */
    TextInput,
    SelectInput,
    PriceInput,
    TextAreaInput,
    LinkedCalendars,
    AjaxSearchSelect,
  },
  setup(_, { emit }) {
    const store = useStore();
    const router = useRouter();
    const me = inject("me");
    const title = ref("");
    const type = ref(null);

    const startCal = ref(null);

    const data = reactive({
      step: 0,
      type: null,
      mediaType: null,
      budget: null,
      description: null,
      title: null,
      startDate: null,
      endDate: null,
      advertiserUserId:
        me?.value?.roles[0] === USER_ROLE_ADVERTISER ? me.value.id : null,
      selectedTags: [],
    });

    const preventNext = computed(() => {
      if (
        data.step === 0 &&
        data?.title !== "" &&
        data?.title?.length > 1 &&
        data.type &&
        data.mediaType &&
        data.budget
      ) {
        return false;
      }
      if (
        data.step === 1 &&
        data.selectedTags.length > 0 &&
        data.advertiserUserId
      ) {
        return false;
      }
      if (data.step === 2 && data.description) {
        return false;
      }
      if (data.step === 3 && data.startDate && data.endDate) {
        return false;
      }

      return true;
    });

    const nextButtonText = computed(() =>
      data.step === 3 ? "salva" : "avanti"
    );

    const next = () => {
      data.step++;
    };
    const prev = () => {
      if (data.step === 0) {
        emit("close");
      } else {
        data.step--;
      }
    };

    const allTags = computed(() => store.getters["tags/tags"]);

    const advertisers = computed(() =>
      store.getters["user/users"].filter(
        (user) => user.roles[0] === USER_ROLE_ADVERTISER
      )
    );

    //const selectedTags = reactive([])
    const onTagSelect = (tag) => {
      const index = data.selectedTags.indexOf(tag);
      if (index === -1) {
        data.selectedTags.push(tag);
      }
    };
    const onTagDeSelect = (tag) => {
      const index = data.selectedTags.indexOf(tag);
      if (index !== -1) {
        data.selectedTags.splice(index, 1);
      }
    };

    const onAdvertiserChange = (advertiser) => {
      data.advertiser = advertiser;
      data.advertiserUserId = advertiser.id;
      console.log(data);
      //data.advertiserUserId = advertisers.value.find(ad => ad.userName === advertiser).id
    };

    const selectedSocials = reactive([]);

    const addSocial = (social) => {
      const index = selectedSocials.indexOf(social);
      if (index === -1) {
        selectedSocials.push(social);
      }
    };

    const removeSocial = (social) => {
      const index = selectedSocials.indexOf(social);
      if (index !== -1) {
        selectedSocials.splice(index, 1);
      }
    };

    const onStartDate = (cell) => {
      data.startDate = cell;
    };

    const onEndDate = (cell) => {
      data.endDate = cell;
    };

    const onTitleInput = (e) => {
      data.title = e.text;
    };

    const onBudgetInput = (e) => {
      if (e.text) {
        data.budget = e.text;
      } else {
        data.budget = e.target.value;
      }
    };
    const onTypeChange = (e) => {
      if (e.target) {
        data.type = e.target.value;
      } else {
        data.type = e;
      }
    };
    const onMediaTypeChange = (e) => {
      if (e.target) {
        data.mediaType = e.target.value;
      } else {
        data.mediaType = e;
      }
    };
    const descriptionInput = (e) => {
      data.description = e.target.value;
    };

    const onSubmit = async () => {
      if (data.step === 3) {
        createCampaign();
      } else {
        next();
      }
    };
    const createCampaign = async () => {
      let startTimezoneOffset = data.startDate.getTimezoneOffset();
      let endTimezoneOffset = data.endDate.getTimezoneOffset();

      const newStart = new Date(data.startDate).setUTCHours(
        0 - startTimezoneOffset / 60,
        0,
        0
      );

      const newEnd = new Date(data.endDate).setUTCHours(
        23 - endTimezoneOffset / 60,
        59,
        59
      );

      const campaign = {
        title: data.title,
        type: data.type,
        mediaType: data.mediaType,
        description: data.description,
        start: new Date(newStart).toISOString(),
        end: new Date(newEnd).toISOString(),
        budget: data.budget,
        advertiserUserId: data.advertiserUserId,
      };

      try {
        const response = await store.dispatch("campaigns/create", {
          data: campaign,
        });

        if (data.selectedTags.length > 0) {
          await store.dispatch("campaigns/syncTags", {
            id: response.id,
            tags: data.selectedTags.map((t) => {
              return {
                tagId: t.id,
                tag: t,
                campaignId: response.id,
              };
            }),
          });
        }

        await store.dispatch("messages/message", {
          title: "campagna creata",
          message: data.title,
        });
        setTimeout(() => {
          //router.push('/campaigns/'+ response.id)
          router.push("/campaigns");
          // location.reload();
          window.location = "/campaigns";
        }, 100);

        emit("close");
      } catch (error) {
        await store.dispatch("messages/message", {
          title: "errore nella creazione della campagna",
          message: error.message,
        });
      }
    };

    const onDatesChanged = ({ startDate, endDate }) => {
      data.startDate = startDate;
      data.endDate = endDate;
    };

    const initStartDay = new Date();
    initStartDay.setUTCHours(0, 0, 0, 0);
    const initEndDay = new Date();
    initEndDay.setUTCHours(23, 59, 59, 0);

    return {
      me,
      title,
      allTags,
      emit,
      onSubmit,
      addSocial,
      removeSocial,
      //selectedTags,
      onTagSelect,
      onTagDeSelect,
      preventNext,
      next,
      prev,
      data,
      onStartDate,
      onEndDate,
      type,
      nextButtonText,
      onTitleInput,
      onBudgetInput,
      onTypeChange,
      onMediaTypeChange,
      descriptionInput,
      startCal,
      onDatesChanged,
      initStartDay,
      initEndDay,
      onAdvertiserChange,
      advertisers,
      ApiEndPoints,
      USER_ROLE_ADVERTISER,
    };
  },
};
</script>
<style lang="postcss"></style>
