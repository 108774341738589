<template>
    <label :class="className">
        <div class="flex-container heading-and-error no-grow">
            <h3 class="bebas nomargin">{{label}}</h3>
            <ErrorMessage v-if="editing && required" :validationStatus="vuel.text" />
        </div>
        
        <textarea
            v-if="editing && required" 
            required 
            v-model="text"
            :name="name" 
            :placeholder="placeholder"
            class="noborder nopadding pl8 h100"
            @blur="handleBlur('text')"
            @input="()=>$emit('input', {text:text , name:name})"
        >
        </textarea>
        <textarea
            v-else-if="editing && !required" 
            v-model="text"
            :name="name" 
            :placeholder="placeholder"
            class="noborder nopadding pl8 h100"
            @blur="handleBlur('text')"
            @input="()=>$emit('input', {text:text , name:name})"
        >
        </textarea>
        <textarea v-else :placeholder="placeholder"  class="noborder nopadding pl8" disabled :value="value">
        </textarea>
    </label>
   
</template>
<script>
import { ref} from 'vue'
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators"
import ErrorMessage from "./ErrorMessage";

export default {
    props:{
        name:{
            type: String,
            default:''
        },
        label:{
            type: String,
            default:''
        },
        placeholder:{
            type:String,
            default:'insert value here'
        },
        value:{
            type:String,
            default:''
        },
        editing:{
            type:Boolean,
            default:false
        },
        className:{
            type:String,
            default:'flex-container flex-col pr8'
        },
        required:{
            type:Boolean,
            default:true
        }
    },
    components:{
        ErrorMessage
    },
    setup(props){
        
        const text = ref(props.value);
        const rules = { text: { required }}
        let vuel
        if(props.required){
             vuel = useVuelidate(rules,{ text })
            
        }
        
        const handleBlur = (key) =>{
            if(props.required){
                vuel.value[key].$dirty = true;
            }
            
        }
        return{
            text,
            vuel,
            handleBlur
        }
    }
}
</script>
<style lang="postcss">
    .layout-content textarea, .layout-content textarea:focus{
        height: 100%;
    }
    /* .edit-user-scope {
        & .layout-content textarea, .layout-content textarea:focus{
            height: 100%;
        }
    } */
</style>