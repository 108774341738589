<template>
  <teleport to="#overlay">
    <div class="overlay w100 h100 abs">
      <div class="overlay-panel rel pb24">
        <form
          class="flex-container flex-col min-height-inherit"
          id="add-post-panel"
          @submit.prevent="onSubmit"
        >
          <div class="flex-container flex-col no-grow">
            <div class="panel-header p24">
              <div class="mini-logo"></div>
              <h2 class="panel-title nomargin bebas">
                {{
                  data?.advert?.ads && data.advert.ads.length > 0
                    ? "Modifica inserzione"
                    : "Aggiungi il primo post"
                }}
              </h2>
            </div>
          </div>

          <h2 class="panel-title nomargin bebas pl24 pr24">
            {{ data.advert.name }}
          </h2>

          <div class="flex-container flex-col pl24 pr24 scrollable-content">
            <p>Aggiungi nuovo post</p>
            <text-input
              :label="'Nome'"
              :value="data.advertname"
              :editing="true"
              :placeholder="'Nome inserzione'"
              :name="'name'"
              @input="onNameInput"
              ref="nameInput"
            >
            </text-input>
            <text-input
              :label="'Link'"
              :value="data.link"
              :editing="true"
              :placeholder="'Link ottenuto da facebook'"
              :name="'link'"
              @input="onLinkInput"
              ref="linkInput"
            >
            </text-input>
            <p>Post attualmente inseriti :</p>
            <ul class="layout-list" v-if="data.advert.ads">
              <PostElement
                v-for="ad in data.advert.ads"
                :key="ad.postId"
                :post="ad"
                :editing="true"
                :removable="true"
                @remove-post="onRemove"
              ></PostElement>
            </ul>
          </div>
          <div class="flex-container panel-footer abs w100">
            <div class="flex-container layout-list-footer">
              <input
                type="reset"
                :disabled="false"
                value="chiudi"
                class="w100 noborder nopadding bebas"
                @click.prevent="onClose"
              />
              <input
                type="submit"
                :disabled="!data.canSubmit"
                :value="nextButtonText"
                class="w100 noborder nopadding bebas"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </teleport>
</template>
<script>
import { computed, ref, reactive } from "vue";
import { useStore } from "vuex";
import TextInput from "@/components/formelements/TextInput";
import PostElement from "@/components/campaigns/elements/PostElement";
import { parseFacebookLink } from "@/utils/FacebookUtils";
import { unassignFacebookAdvertPost } from "@/utils/CampaignFunctions";
import axios from "axios";
export default {
  emits: ["close", "add-success", "remove-success", "oauth-error"],
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },
    advert: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    TextInput,
    PostElement,
  },
  setup(props, { emit }) {
    const store = useStore();
    const data = reactive({
      advertname: null,
      link: null,
      canSubmit: false,
      advert: props.advert,
      campaign: props.campaign,
    });

    const nextButtonText = computed(() => {
      return "aggiungi";
    });

    const nameInput = ref(null);
    const linkInput = ref(null);
    const onNameInput = async ({ text }) => {
      data.advertname = text;
      try {
        const { postId } = await parseFacebookLink(data.link);
        data.canSubmit = postId && data.advertname?.length > 2;
      } catch (error) {
        data.canSubmit = false;
      }
    };

    const onLinkInput = async ({ text }) => {
      data.link = text;
      try {
        const { postId } = await parseFacebookLink(data.link);
        data.canSubmit = postId && data.advertname?.length > 2;
      } catch (error) {
        data.canSubmit = false;
      }
    };

    const onClose = () => {
      emit("close");
    };

    const onSubmit = async () => {
      try {
        data.link.replace("business.facebook", "www.facebook"); //

        var { postId } = await parseFacebookLink(data.link);
        var substr = "";
        var sp = postId.split("_"); //

        ///////////////////////////////////////////////////////////////

        if (!parseInt(sp[0]) || !parseInt(sp[1])) {
          //await axios.get("https://graph.facebook.com/v17.0/Repubblica_pfbid0bC6MetsAr958vtg9Hb7eZJr4vjUgEnEJizs61n17GaBUk4CWv1ru2eswPDzTkA7ul")
          await axios
            .get(
              "https://graph.facebook.com/v17.0/" +
                postId +
                "?access_token=EAAWZAuERZCpeQBACG5Fl5rXcWrGMEMPhW68a8b1g9HucTwc3BRZBtzyLOD5m85dRs5qWiLnPzNZCl93inqcuAiHa9UXAs4noPduuivNMsLaZAuALS6IfS9ZA2ZAam8yf9hjmdmsbOpMbG8vicECoT49U61GMZCpGq1MgdOUYzlJdXgZDZD"
            )
            .then((response) => {
              console.log("success, length: " + response.length);
            })
            .catch((err) => {
              console.log(err.response.data.error.message);
              var st = err.response.data.error.message;
              alert(err.response.data.error.message);
              var ind1 = st.indexOf("_");
              var ind2 = st.indexOf("'", ind1);
              substr = st.substring(ind1 + 1, ind2); //postid
              //console.log(err.status);
              //console.log(err.response.status)
            });

          await axios
            .get(
              "https://graph.facebook.com/v17.0/" +
                sp[0] +
                "?access_token=EAAWZAuERZCpeQBACG5Fl5rXcWrGMEMPhW68a8b1g9HucTwc3BRZBtzyLOD5m85dRs5qWiLnPzNZCl93inqcuAiHa9UXAs4noPduuivNMsLaZAuALS6IfS9ZA2ZAam8yf9hjmdmsbOpMbG8vicECoT49U61GMZCpGq1MgdOUYzlJdXgZDZD"
            )
            .then((response) => {
              console.log("success");
              //console.log(response);
              postId = response.data.id + "_" + substr;
              data.advert.socialId = response.data.id;
            })
            .catch((err) => {
              console.log(err.response.data.error.message);
            });
        } else {
          await axios
            .get(
              "https://graph.facebook.com/v17.0/" +
                postId +
                "?access_token=EAAWZAuERZCpeQBACG5Fl5rXcWrGMEMPhW68a8b1g9HucTwc3BRZBtzyLOD5m85dRs5qWiLnPzNZCl93inqcuAiHa9UXAs4noPduuivNMsLaZAuALS6IfS9ZA2ZAam8yf9hjmdmsbOpMbG8vicECoT49U61GMZCpGq1MgdOUYzlJdXgZDZD"
            )
            .then((response) => {
              console.log("success");
              postId = response.data.id;
              //alert('pattern 2');
              // alert(postId);
            })
            .catch((err) => {
              //alert('P');
              console.log(err.response.status);
            });
        }

        //////////////////////////////////////

        if (postId) {
          //id socialid_postid
          const response = await store.dispatch("post/insertFacebookAdvPost", {
            name: data.advertname, //nome insersione
            socialId: data.advert.socialId, //nome nella pagina (tradotto in id)
            postId: postId, //id socialid_postid
            campaignId: props.campaign.id, //id campagna (DB)
            facebookAdId: props.advert.facebookAdId,
          });
          await store.dispatch("messages/message", {
            title: "post associato",
            message: "post " + data.advertname + " associato correttamente",
          });
          data.advert.ads.push(response);

          nameInput.value.$el.querySelector("input").value = "";
          linkInput.value.$el.querySelector("input").value = "";

          emit("add-success");
        }
      } catch (error) {
        if (
          error.message.startsWith(
            "Invalid OAuth access token." ||
              error.message.startsWith("(#200) Provide valid app ID")
          )
        ) {
          emit("close");
          emit("oauth-error");
        } else {
          await store.dispatch("messages/message", {
            title: "errore nell'associazione",
            message: error.message,
          });
        }
      }
    };

    const onRemove = async (post) => {
      await unassignFacebookAdvertPost(post);
      data.advert.ads = data.advert.ads.filter((p) => p.postId !== post.postId);
      emit("remove-success");
    };

    return {
      data,
      nextButtonText,
      onRemove,
      onClose,
      onSubmit,
      onNameInput,
      onLinkInput,
      linkInput,
      nameInput,
    };
  },
};
</script>
<style lang="postcss" scoped>
#add-post-panel .add-social-list .layout-list {
  max-height: 100%;
  height: initial;
  overflow-y: auto;
}
.overlay-panel #add-post-panel input[type="reset"]:focus {
  outline: none;
  background-color: #2cbeff;
  color: #f2f2f2;
}
#add-post-panel img {
  max-width: 100%;
}

.layout-list-footer {
  font-size: 22px;
  line-height: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
}
.scrollable-content {
  height: 40vh;
  overflow-y: auto;
}
a.post-link {
  color: white;
  background-color: #2cbeff;
  text-align: center;
  padding: 1em 0;
  text-decoration: none;
}
.overlay-panel .flex-container.scrollable-content {
  flex: unset;
}
</style>
