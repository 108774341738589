<template>
  <ResizableColumnContainer :maxCol="2" @total-size="onTotalSize">
    <resizable-column
      v-show="Campaignsmostralista"
      :key="componentKey"
      :className="`flex-container flex-col layout-content w10 innovativestudio_colonne infotronik_social_lista`"
      :columnIndex="0"
      @column-resize="campaignColumnResize"
      ref="list"
    >
      <CampaignsList
        :campaigns="campaigns"
        :selectedId="route.params.id ? route.params.id : ''"
      ></CampaignsList>
    </resizable-column>
    <resizable-column
      v-show="CampaignsDettaglilista"
      :className="`flex-container flex-col layout-content w100 innovativestudio_colonne`"
      :columnIndex="1"
      @column-resize="campaignColumnResize"
      ref="card"
      :style="cardStyle"
      :resizable="false"
    >
      <Tabs v-if="campaign" :campaign="campaign" :key="campaign.id"></Tabs>
      <ColumnPlaceHolder v-else></ColumnPlaceHolder>
    </resizable-column>
  </ResizableColumnContainer>
</template>
<script>
import { useStore } from "vuex";
import { useRoute, onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
import { computed, onMounted, ref } from "vue";
import ResizableColumn from "@/components/ResizableColumn";
import ResizableColumnContainer from "@/components/ResizableColumnContainer";
import Tabs from "@/components/campaigns/Tabs";
import CampaignsList from "@/components/campaigns/CampaignsList";
import ColumnPlaceHolder from "@/components/ColumnPlaceHolder";
import { apiCampaignFunction } from "@/api/campaigns";

import {
  list,
  listStyle,
  card,
  cardStyle,
  onTotalSize,
  campaignColumnResize,
} from "@/utils/ColumnsResizer";
export default {
  ///// sistemo lo colenne a seconda di quelllo che mi arriva
  data() {
    return {
      componentKey: 0,
      Campaignsmostralista: true,
      CampaignsDettaglilista: false,
    };
  },
  created() {
    this.emitter.on("Campaignsmostralista", (show) => {
      this.Campaignsmostralista = show;
      this.componentKey += 1;
    });
    this.emitter.on("CampaignsDettaglilista", (show) => {
      this.CampaignsDettaglilista = show;
      this.componentKey += 1;
    });

    /// visualizzazione di base colonne
    this.Campaignsmostralista = true; //faccio vedere la lista delle campagne
    this.CampaignsDettaglilista = false; //non faccio vedere la colonna dei dettagli

    ///nel caso mi trovo arrivare dai target sistemo le colonne
    if (window.location.pathname.includes("/campaigns/")) {
      /// visualizzazione di base colonne
      this.Campaignsmostralista = false; //faccio vedere la lista delle campagne
      this.CampaignsDettaglilista = true; //non faccio vedere la colonna dei dettagli

      ////////////breadcrumb//////////////
      //creo il breadcrumb per l'utente///
      this.routes = ref([{ name: "Campaigns", path: "/campaigns" }]);
      ////////////breadcrumb//////////////
      //creo il breadcrumb per l'utente///

      this.emitter.emit("breadcrumb", this.routes);
    }
  },

  components: {
    ResizableColumn,
    ResizableColumnContainer,
    CampaignsList,
    ColumnPlaceHolder,
    Tabs,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const campaigns = computed(() => {
      return store.getters["campaigns/filtered"]
        ? store.getters["campaigns/filtered"]
        : store.getters["campaigns/paged"];
    });

    const routes = ref([{ name: " ", path: "/" }]);

    const tags = computed(() => {
      return store.getters["tags/tags"];
    });

    const socials = computed(() => {
      return store.getters["social/social"];
    });

    const campaign = ref(null);

    onMounted(async () => {
      if (route.params?.id) {
        campaign.value = await apiCampaignFunction.show(route.params.id);
      } else {
        campaign.value = null;
      }
    });

    onBeforeRouteLeave(async (to) => {
      const cardWidth = card?.value?.$el
        ? card.value.$el.getBoundingClientRect().width + "px"
        : "70%";

      if (list?.value?.$el) {
        store.commit("campaigns/savecolumnsizes", {
          listW: list.value.$el.getBoundingClientRect().width + "px",
          cardW: cardWidth,
        });
      }

      if (to.params.id) {
        campaign.value = await apiCampaignFunction.show(to.params.id);
      } else {
        campaign.value = null;
      }
    });

    onBeforeRouteUpdate(async (to, from) => {
      if (to.params.id !== from.params.id) {
        campaign.value = await apiCampaignFunction.show(to.params.id);
      } else if (!to.params.id) {
        campaign.value = null;
      }
    });

    return {
      campaigns,
      campaign,
      campaignColumnResize,
      list,
      listStyle,
      card,
      cardStyle,
      onTotalSize,
      tags,
      socials,
      route,
      routes,
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style: none;
}
li {
  display: flex;
  min-width: 300px;
}
span {
  padding: 0.5em;
}
</style>
