<template>
    <li :class="className + ' fb-post-element pl8'">
        <FacebookPostImage
            :url="post.fullPicture"
            :id="post.postId"
        ></FacebookPostImage>
        <div class="flex-container flex-col w80">
            <p class="layout-list-item-subtitle f12 nomargin pl8 lh24"><i>{{id || 'no id from db :S'}}</i></p>
            <div class="layout-list-item-title nomargin pl8 lh24 pr8">
                {{post.story ? post.story : post.message}} 
            </div>
        </div>
      
        <InflooButton
            @click="() =>openLocation(post.permalink)"
            :className="'add-post-to-campaign button primary flex-container flex-center-align flex-center-justify '"
        >
            vedi
        </InflooButton> 
        <InflooButton 
            v-if="removable && editing"
            :className="'remove-post-from-campaign nomargin button primary flex-container flex-center-align flex-center-justify'"
            @click.prevent="()=>removePost(post)"
        >
            rimuovi <i class="lni lni-close"></i>
        </InflooButton>
        
    </li>
</template>
<script>
import { inject, computed} from 'vue'
import {useStore} from 'vuex'
import {openLocation} from '@/utils/CampaignFunctions'
import{
  USER_ROLE_SUPER,
  USER_ROLE_ADMIN,
} from '@/utils/RolesAndPolicies'
import InflooButton from '@/components/buttons/InflooButton'
import FacebookPostImage from '@/components/campaigns/facebook/posts/FacebookPostImage'
export default {
    emits:['confirm-post', 'remove-post', 'list-select'],
    props:{
        post:{
            type:Object,
            default:()=>{}
        },
        className:{
            type:String,
            default:'layout-list-item flex-container flex-center-align'
        },
        editing:{
            type:Boolean,
            default:false
        },
        removable:{
            type:Boolean,
            default:false
        }
    },
    components:{
        InflooButton,
        FacebookPostImage
    },
    setup(props, context){
        
        const me = inject('me')
        const store = useStore()
        const socialName = computed(()=>{
            return store.getters['social/social'].find(s => s.socialId === props.post.socialId)?.name || ''
        })
        const removePost = (post)=>{
            context.emit('remove-post', post)
        }

        let id = '__'
        if(props.post.permalink) {
            const arr = props.post.permalink.split('/').slice(3)
            id = arr[0]+'_'+arr[2]
        }
        return{
            me,
            USER_ROLE_SUPER,
            USER_ROLE_ADMIN,
            socialName,
            removePost,
            id,
            openLocation
        }
    }
}
</script>
<style scoped>
.layout-content .layout-list-item a {
    
    font-weight: normal;
}
.layout-content input[type="reset"] {
    height: 40px;
}
.h40px{
    height: 40px;
}
.add-post-to-campaign{
    text-decoration: none;
    color: #ffffff;
    background-color: #2cbeff;
    text-align: center;
    line-height: 21px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px;
    border: none;
    max-width: 68px;
}
.remove-post-from-campaign{
    background-color: #2f3a3f;
    color: #F2F2F2;
    text-decoration: none;
    text-align: center;
    line-height: 21px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px;
    border: none;
    max-width: 75px;
}
.remove-post-from-campaign:hover,.pending a.remove-post-from-campaign:hover{
    background-color: #2cbeff;
    color:#000000;
}
.remove-post-from-campaign:disabled {
    opacity: 0;
}
.remove-post-from-campaign:focus {
    outline: none;
}
.fb-post-element img {
    height: 36px;
    width: 36px;
    object-fit: cover;

}


</style>