<template>
    <label class="flex-conatiner flex-col w100 mediapreview">
        <h3 class="bebas nomargin">Anteprima</h3>
        <video
            v-if="mediaType === 'Video' && mediaLink"
            class="videoplayer mb8" 
            controls
        >
            <source :src="mediaLink" type="video/mp4">
        </video>
        <img
            v-else-if="mediaType === 'Image' && mediaLink"
            :src="mediaLink"
            class="mb8"
            loading="lazy"
        >
        <img
            v-else
            src="../../../css/img/placeholder.png"
            class="mb8"
            loading="lazy"
        >
    </label>
</template>
<script>
export default {
    props:{
        mediaType:{
            type:String,
            default:''
        },
        mediaLink:{
            type:String,
            default:null
        }
    }
    
}
</script>