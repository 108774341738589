<template>
    <div class="flex-container flex-col analytics-totals p8 no-grow">
        <h2 class="bebas nomargin mb16">Totali campagna</h2>
        <text-input 
            v-if="isAdmin() || isPublisher()"
            :label="'Costo'" 
            :value="formatNumber(data.cost) + ' €'" 
            :editing="false" 
            :name="'clicksLink'"
            :className="'flex-container flex-col flex-start-align flex-start-justify pt16'" :labelClassName="'nomargin'">
        </text-input>
        <FacebookInsight v-if="isAdmin()" :insight="data" :mediaType="campaign.mediaType"></FacebookInsight>
        <AdvertiserFacebookInsight v-else :insight="data"></AdvertiserFacebookInsight>
    </div>
</template>
<script>
import { reactive } from 'vue'
import { useCapabilities } from '@/utils/Capabilities'
import FacebookInsight from '@/components/campaigns/analytics/facebook/FacebookInsight'
import AdvertiserFacebookInsight from '@/components/campaigns/analytics/facebook/AdvertiserFacebookInsight'
import TextInput from '@/components/formelements/TextInput'
import { formatNumber } from '@/utils/NumberFunctions'
import { getFacebookAdvertInsightTotals, getFacebookPostsInsightTotals } from '@/utils/AnalyticsFunctions'
export default {
    props: {
        socials: {
            type: [Object, String],
            default: ''
        },
        campaign: {
            type: Object,
            default: () => { }
        },
    },
    components: {
        FacebookInsight,
        AdvertiserFacebookInsight,
        TextInput
    },
    setup(props) {
        const { isAdmin, isPublisher } = useCapabilities()

        let data = reactive({
            cost: 0,
            linkClicks: 0,
            otherClicks: 0,
            photoView: 0,
            reactions: 0,
            comments: 0,
            shared: 0,
            impressions: 0,
            impressionsSponsored: 0,
            impressionsOrganic: 0,
            totalCoverage: 0,
            sponsoredCoverage: 0,
            organicCoverage: 0,
            videoViews3S: 0,
            videoViews3SUnique: 0,
            videoViews3SOrganic: 0,
            videoViews3SSponsored: 0,
            videoViews10S: 0,
            videoViews10SOrganic: 0,
            videoViews10SSponsored: 0,
            videoViews10SUnique: 0,
            videoViews15S: 0,
            videoPlay: 0,
            videoPlayAuto: 0
        })


        const updateData = (insightObject) => {
            Object.keys(insightObject).forEach(key => {
                data[key] += insightObject[key]
            })
        }

        props.socials.forEach(social => {

            const { advertisements, posts } = social
            if (posts) {
                const postsInsights = getFacebookPostsInsightTotals(posts)
                updateData(postsInsights)
            }
            if (advertisements) {
                advertisements.forEach(advertisement => {
                    const adInsights = getFacebookAdvertInsightTotals(advertisement.ads)
                    console.log('adInsights')
                    console.log(adInsights)
                    updateData(adInsights)
                })
            }
        })


        return {
            data,
            isAdmin,
            isPublisher,
            formatNumber
        }
    }


}
</script>
<style lang="postcss">
.analytics-totals {

    & label h3 {
        font-size: .9em;
    }
}
</style>