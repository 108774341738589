<template>
    <div class="flex-container flex-col w100">
        <div v-if="hasPosts" class="post-container flex-container flex-col mb24 mt8">
            <div class="flex-container flex-center-align flex-space-between-justyfy no-grow">
                <p class="bebas nomargin pl8">post</p>
            </div>
            <slot name="posts"></slot>
        </div>
        <div v-if="hasAdverts && isAdmin()" class="adverts-container flex-container flex-col">
            <div class="flex-container flex-center-align flex-space-between-justyfy no-grow">
                <p class="bebas nomargin pl8">GHOST</p>
            </div>
            <slot name="adverts"></slot>
        </div> 
        <div v-if="hasPublishDates" class="publishdates-container flex-container flex-col">
            <div class="flex-container flex-center-align flex-space-between-justyfy no-grow">
                <p class="bebas nomargin pl8">In attesa di pubblicazione</p>
            </div>
            <slot name="publishdates"></slot>
        </div>   
    </div>
</template>
<script>
import {useCapabilities} from '@/utils/Capabilities'
export default {
    props:{
        hasPosts:{
            type:Boolean,
            default:true
        },
        hasAdverts:{
            type:Boolean,
            default:true
        },
        hasPublishDates:{
            type:Boolean,
            default:true
        }
    },
    setup(){
        const {isAdmin} = useCapabilities()
        return {
            isAdmin
        }
    }
}
</script>
