<template>
    <div class="layout-list-header">
        <span class="layout-list-header-title bebas f20 pl16">Campaigns</span>
        <a v-if="canCreateCampaign" href="#" class="btn-new" @click.prevent="openCreator">+</a>
        <CreateCampaignPanel v-if="creatorOpen" @close="creatorOpen = false"></CreateCampaignPanel>
    </div>
    <ListHeader
        :defaultSort="defaultSort"
        @input="onInput"
        @change="onSort"
        :isCampaign="true"
    ></ListHeader>
    <div class="layout-list-content infotronik_campagne_campaignlist">
        <ul class="layout-list nopadding nomargin">
            <CampaignListElement 
                v-for="campaign in campaigns" 
                :key="campaign.id" 
                :campaign="campaign" 
                :selectedId="selectedId"
            ></CampaignListElement>
        </ul>
    </div>
    <Pagination
       :pagination="pagination"
       @page-change="onPageChange"
    ></Pagination>
</template>
<script>
import {ref, computed} from 'vue'
import {useStore} from 'vuex'
import {useCapabilities} from '@/utils/Capabilities'
import CampaignListElement from '@/components/campaigns/elements/CampaignListElement'
import CreateCampaignPanel from '@/components/campaigns/panels/CreateCampaignPanel'
import ListHeader from '@/components/ListHeader'
import Pagination from '@/components/Pagination'
export default {
    props:{
        campaigns:{
            type:Array,
            default:()=>[]
        },
        selectedId:{
            type:String,
            default:''
        },
        defaultSort: {
            type: String,
            default: 'data creazione desc'
        }
    },
    async mounted() {
        const defaultSorting = { type: 'data creazione desc' };
        this.onSort({ target: { value: defaultSorting } });
    },
    data() {
        return {
            selectedSort: this.defaultSort
        }
    },
    components:{
        CreateCampaignPanel,
        CampaignListElement,
        ListHeader,
        Pagination
    },
    setup(/* props */){
        const store = useStore()
        const creatorOpen = ref(false)
        const openCreator = async()=>{
           
            creatorOpen.value = true
        }
        const onInput = (filter)=>{
            store.dispatch('campaigns/filter', filter) 
        }

        const onSort =(sorting)=>{        
            store.dispatch('campaigns/sort', sorting)
        }

        const pagination = computed(()=>{
            return store.getters['campaigns/pagination']
        })
        const onPageChange = async (page)=>{
            await store.dispatch('campaigns/index', {PageNumber:page})
        }

        const {isAdmin, isAdvertiser} = useCapabilities()

        const canCreateCampaign = computed(()=>{
            return isAdmin() || isAdvertiser()
        })

        return{
            onInput,
            creatorOpen,
            onSort,
            pagination,
            onPageChange,
            openCreator,
            canCreateCampaign
        }
    }
}
</script>