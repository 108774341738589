<template>
    <li :class="className + ' fb-post-element pl8'">
        
        <div class="flex-container">
            {{timeStampToFormattedDateTime(data.start.dateTime,{ year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute:'2-digit' })}}
            
        </div>
        <InflooButton
            v-if="editing"
            @click="() =>associatePost(calendarID)"
            :className="'add-published-post button primary flex-container flex-center-align flex-center-justify '"
        >
            associa
        </InflooButton> 
        <InflooButton
            v-if="editing"
            @click="() =>deAssociatePost(calendarID)"
            :className="'remove-published-post button secondary flex-container flex-center-align flex-center-justify '"
        >
            rimuovi
            <i class="lni lni-close"></i>
        </InflooButton> 
        
    </li>
</template>
<script>
import{
  USER_ROLE_SUPER,
  USER_ROLE_ADMIN,
} from '@/utils/RolesAndPolicies'
import InflooButton from '@/components/buttons/InflooButton'
import {timeStampToFormattedDateTime} from '@/utils/DateTimeUtils'
export default {
    emits:['list-select', 'assign-publish-date','remove-publish-date'],
    props:{
        data:{
            type:[Object],
            default:()=>{}
        },
        className:{
            type:String,
            default:'layout-list-item flex-container flex-center-align'
        },
        calendarID:{
            type:String,
            default:null
        },
        editing:{
            type:Boolean,
            default:false
        },
        removable:{
            type:Boolean,
            default:false
        }
    },
    components:{
        InflooButton
    },
    setup( props, context){
       
        const associatePost = (calendarID)=>{
            context.emit('assign-publish-date', {calendarID:calendarID, data:props.data})
        }
        const deAssociatePost = (calendarID)=>{
            context.emit('remove-publish-date', {calendarID:calendarID, data:props.data})
        }
        return{
            USER_ROLE_SUPER,
            USER_ROLE_ADMIN,
            timeStampToFormattedDateTime,
            associatePost,
            deAssociatePost
        }
    }
}
</script>
<style scoped>

.add-published-post, .remove-published-post{
    max-width: 50px;
}

</style>