<template>
     <teleport to="#overlay">
        <div class="overlay w100 h100 abs">
            <div class="overlay-panel rel pb24">
                <form class="flex-container flex-col min-height-inherit" id="add-post-panel" @submit.prevent="onSubmit">
                     <div class="flex-container flex-col no-grow">
                        <div class="panel-header p24">
                            <div class="mini-logo"></div>
                            <h2 class="panel-title nomargin bebas">Crea nuova inserzione</h2>
                        </div>
                     </div>
                     
                    <h2 class="panel-title nomargin bebas pl24 pr24">{{social.name}}</h2>    
                    
                    <div class="flex-container flex-col pl24 pr24 scrollable-content pb24">
                        <p class="pb24">
                            Una inserzione è composta da un <strong>insieme di post</strong>
                        </p>
                        <text-input
                            :label="'Nome'"
                            :value="''"
                            :editing="true"
                            :placeholder="'Nome inserzione'"
                            :name="'name'"
                            @input="onNameInput"     
                        >
                        </text-input>
                       
                    </div>
                    <div class="flex-container panel-footer abs w100">
                        <div class="flex-container layout-list-footer">
                            <input type="reset" :disabled="false" value="annulla" class="w100 noborder nopadding bebas" @click.prevent="onClose">
                            <input type="submit" :disabled="!canSubmit" :value="nextButtonText" class="w100 noborder nopadding bebas">
                        </div>
                    </div>
                </form>
                
            </div> 
         </div>
      
    </teleport>
</template>
<script>
import {computed, reactive, ref} from 'vue'
import {useStore} from 'vuex'
import TextInput from '@/components/formelements/TextInput'
export default {
    emits:['close', 'add-success'],
    props:{
        campaign:{
            type:Object,
            default:()=>{}
        },
        social:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
       TextInput
    },
    setup(props, {emit}){
        const store = useStore()
        const advertname = ref(null)
        const canSubmit = ref(false)
        
        const data = reactive({
            selectedSocial:props.social,
            campaignId:props.campaign.id,
            posts:[],
        })
        
        const nextButtonText = computed(() => {
            if(data.step === 0 ){
                return 'crea inserzione'
            } else {
                return 'aggiungi'
            }
        })

        const onNameInput = ({text}) =>{
            advertname.value = text  
            canSubmit.value = advertname.value?.length > 2 
        }        
        const onClose = ()=>{   
            emit('close')
        }

        

        const onSubmit = async()=>{
            
            try {
                const advert = await store.dispatch('post/createFacebookAdvertisment',{
                    name: advertname.value,
                    socialId: data.selectedSocial.socialId,
                    campaignId: data.campaignId
                })
                
                /* await store.dispatch('messages/message', {
                        title: 'inserzione Creata',
                        message:'inserzione '+  advertname.value+' creata correttamente'
                }) */
                emit('add-success', advert) 
                
                //emit('close')
                
            } catch (error) {
                await store.dispatch('messages/message', {
                    title:'errore durante l`associazione ', 
                    message:error.message 
                })
                emit('close')
            }          
        }

        

       

        return{
            advertname,
            nextButtonText,
            data,
            onClose,
            onSubmit,
            canSubmit,
            onNameInput
        }
    }
    
}
</script>
<style lang="postcss" scoped>
#add-post-panel .add-social-list .layout-list {
    max-height: 100%;
    height: initial;
    overflow-y: auto;
}
.overlay-panel #add-post-panel  input[type="reset"]:focus {
  outline: none;
  background-color: #2cbeff;
  color: #F2F2F2;
}
#add-post-panel img {
    max-width: 100%;
}

.layout-list-footer{
        font-size: 22px;
    line-height: 40px;
    height: 40px;
    position: relative;
    cursor: pointer;
}
.scrollable-content{
    height: 40vh;
    overflow-y: auto
}
a.post-link {
    color: white;
    background-color: #2cbeff;
    text-align: center;
    padding: 1em 0;
    text-decoration: none;
}
.overlay-panel .flex-container.scrollable-content{
    flex:unset;
}

</style>