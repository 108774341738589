<template>
    <li :class="className">
        <a 
            @click="onSelect"
            class="layout-list-item-btn flex-container pl8"
        >
            <div class="layout-list-item-title nomargin mt8 mb8">{{social.name ? social.name : 'Nome Social'}} </div>
            <div class="flex-container no-grow">
                <InflooButton
                    v-if="mode==='search' && editing" 
                    class="remove-social-from-campaign flex-center-align flex-center-justify flex-container p12"
                    @click.prevent="()=>requestCampaign(social)"
                >
                    <i class="lni lni-arrow-right-circle"></i>
                </InflooButton>

            </div>
        </a>
    </li>
</template>
<script>

import {computed, ref } from 'vue'
import InflooButton from '@/components/buttons/InflooButton'

export default {
    emits:['list-select', 'request-campaign', 'withdraw-campaign', 'add-to-campaign', 'remove-social-from-campaign'],
    props:{
        social:{
            type:Object,
            default:()=>{}
        },
        mode:{
            type:String,
            default:'search'
        },
        editing:{
            type:Boolean,
            default:false
        },
    },
    components:{
        InflooButton
    },
    setup(props,context){
        const onSelect=()=>{
            context.emit('list-select', props.social)
        }
        const className = computed(()=> {
            return 'layout-list-item'
        })
        const requestCampaign = (social)=>{
            context.emit('request-campaign', social)
        }
        

        const addToCampaign = (social)=>{
            isSelected.value = true
            context.emit('add-to-campaign', social)
        }
        
        const isSelected =ref(false)

        return{
            onSelect,
            className,
            requestCampaign,
            addToCampaign,
            isSelected
        }
    }
    
}
</script>
<style > 

.remove-social-from-campaign{
    background-color: #2f3a3f;
    color: #F2F2F2;
    text-decoration: none;
    text-align: center;
    /* line-height: 21px; */
    cursor: pointer;
    text-transform: uppercase;
    font-size: 24px;
    border: none;
    padding: 0px;
    min-width: 50px;
} 
.remove-social-from-campaign.p12{
    padding: 13px;
}
.remove-social-from-campaign:hover,.pending a.remove-social-from-campaign:hover{
    background-color: #2cbeff;
    color:#000000;
}
.remove-social-from-campaign:disabled {
    opacity: 0;
}
.remove-social-from-campaign:focus {
    outline: none;
}
</style>

