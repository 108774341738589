<template>
    <div class="flex-grow-1 rel">
        <div class="panel-header tab-header pl24 pr24 infotronik_social_header_interno">
            <!-- <div class="mini-logo "></div> -->
           
            <ul 
                class="flex-container layout-list nopadding nomargin flex-end-align h100 infotronik_tabs_contenitore"
            >
                <li class="layout-list-item">
                    <a 
                        href="#" 
                        :class="selected === 'campaign' 
                            ? 'layout-list-item-btn pl16 pr24 selected infotronik_campagna_tab_campsel' 
                            : 'layout-list-item-btn pl16 pr24 infotronik_campagna_tab_campnotsel'" 
                        @click.prevent="onTabSelected('campaign')"
                    >
                        <!-- <span class="pl8">Campagna</span> -->
                        <span class="pl8"><i class="lni lni-dashboard"></i></span>
                    </a>
                </li>
                <li 
                    v-if="isAdmin() || isConcessionaire()"
                    class="layout-list-item"
                >
                    <a 
                        href="#" 
                        :class="selected === 'facebook' 
                            ? 'layout-list-item-btn pl16 pr24 selected infotronik_campagna_tab_pagessel' 
                            : 'layout-list-item-btn pl16 pr24 infotronik_campagna_tab_pagesnotsel'"
                        @click.prevent="onTabSelected('facebook') "
                    >
                        <!-- <i class="lni lni-facebook-original"></i>
                        <span class="pl8">pagine</span> -->
                        <span class="pl8"><i class="lni lni-list"></i></span>
                    </a> 
                </li>
                <li class="layout-list-item">
                    <a 
                        href="#" 
                        :class="selected === 'analytics' 
                            ? 'layout-list-item-btn pl16 pr24 selected infotronik_campagna_tab_analsel' 
                            : 'layout-list-item-btn pl16 pr24 infotronik_campagna_tab_analnotsel'"
                        @click.prevent="onTabSelected('analytics')"
                    >
                        <!-- <i class="lni lni-investment"></i>
                        <span class="pl8">Analitiche</span> -->
                        <span class="pl8"><i class="lni lni-investment"></i></span>
                    </a> 
                </li>
            </ul>
        </div>
        <AdminCampaignCard
            v-if="selected==='campaign' && isAdmin()" 
            :selCampaign="campaign" 
            :key="campaign.id + '-admin'"
        ></AdminCampaignCard>
        <AdvertiserCampaignCard
            v-else-if="selected==='campaign' && isAdvertiser()" 
            :selCampaign="campaign" 
            :key="campaign.id + '-advertiser'"
        ></AdvertiserCampaignCard>
        <PublisherCampaignCard
            v-else-if="selected==='campaign' && (isPublisher() || isConcessionaire())" 
            :selCampaign="campaign" 
            :key="campaign.id + '-publisher'"
        ></PublisherCampaignCard>
        <facebook-pages 
            v-else-if="selected==='facebook'" 
            :key="campaign.id+ '-fbpages'" 
            :campaign="campaign"
        ></facebook-pages>
        <CampaignAnalytics 
            v-else-if="selected==='analytics'" 
            :key="campaign.id + '-analytics'" 
            :campaign="campaign"
        >
        </CampaignAnalytics>
    </div>    
</template>
<script>
import {ref,computed, provide} from 'vue'
import {useRouter, useRoute, onBeforeRouteUpdate} from 'vue-router'
import AdminCampaignCard from '@/components/campaigns/cards/AdminCampaignCard'
import AdvertiserCampaignCard from '@/components/campaigns/cards/AdvertiserCampaignCard'
import PublisherCampaignCard from '@/components/campaigns/cards/PublisherCampaignCard'
import FacebookPages from '@/components/campaigns/facebook/FacebookPages.vue'
import CampaignAnalytics from '@/components/campaigns/analytics/CampaignAnalytics.vue'
import {useCapabilities} from '@/utils/Capabilities'
export default {
    props:{
        selectedTab:{
            type:String,
            default:'campaign'
        },
        campaign:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        AdminCampaignCard,
        AdvertiserCampaignCard,
        PublisherCampaignCard,
        FacebookPages,
        CampaignAnalytics
    },
    setup(props){
        provide('currentCampaign', props.campaign)
        
        const selected = ref(props.selectedTab)
        const router = useRouter()
        const route = useRoute()
        const {isAdmin, isAdvertiser, isPublisher, isConcessionaire} = useCapabilities()

        if(route.query.tab === undefined){
            selected.value = 'campaign'
        }else{
            selected.value = route.query.tab
        }
        const linkedPosts = computed(()=>{
            return props.campaign?.posts || []
        }) 
       
        const onTabSelected = (tab) => {
            
            selected.value = tab
            router.push({path: route.path, query: { tab: tab }})
        }

        onBeforeRouteUpdate(async (to, from) => {
           
            if(to.params.id === from.params.id && to.query !== from.query){
                selected.value = to.query.tab || 'campaign'
            }
        })

        return{
            selected,
            linkedPosts,
            onTabSelected,
            isAdmin,
            isAdvertiser,
            isPublisher,
            isConcessionaire
        }
    }
}
</script>
